import styles from "./styles.module.scss";
import { Options } from '@splidejs/splide';
import { Splide } from '@splidejs/react-splide';
import { THEME_SETTING } from "../../setting/setting";
import { ImageSet } from "../../component-nextv3/ImageSet";
import { Desktop, Mobile } from "../../core-nextv3/util/useResponsive";
import { VideosSet } from "../../component-nextv3/VideoSet/indx";

const BannerSlider = ({ images }: any) => 
{
    const mainOptions : Options = {
        role       : 'banner',
        type       : 'loop',
        rewind     : true,
        perPage    : 1,
        perMove    : 1,
        pagination : false,
        slideFocus : true,
        start      : 0,
        arrows     : true,
        autoplay   : true,
        interval   : 3000,
    }

    return (
      <div className={styles.bannerSlider}>
        <div className={styles.content}>
            <Splide
                options={mainOptions}
                className={styles.slider}
              >
                {images && images?.length > 0 && images?.map((item:any, index:any) => (
                    item.status && <div key={index} className="splide__slide">
                        <Desktop>
                            <a href={item?.url} aria-label="Banner">
                                {item?.type?.value == 'video' ? 
                                <VideosSet 
                                    // aspectRatio={THEME_SETTING.bannerAspectRatio || 1920/813} 
                                    video={item?.desktopVideo}
                                    width={THEME_SETTING.widthBannerMain || 1920} 
                                    height={THEME_SETTING.heightBannerMain || 813} /> 
                                : 
                                <ImageSet 
                                // aspectRatio={THEME_SETTING.bannerAspectRatio || 1920/813} 
                                //   domainFrom={THEME_SETTING.domainFrom} 
                                //   domainTo={THEME_SETTING.domainTo}
                                  image={item?.desktop} 
                                  width={THEME_SETTING.widthBannerMain || 1920} 
                                  height={THEME_SETTING.heightBannerMain || 813} 
                                  priority={true}/>}
                            </a>
                        </Desktop>
                        <Mobile>
                            <a href={item?.url} aria-label="Banner"> 
                                {item?.type?.value == 'video' ? 
                                    <VideosSet 
                                        // aspectRatio={THEME_SETTING.bannerMobileAspectRatio || 1000/1500}
                                        video={item?.mobileVideo}
                                        width={THEME_SETTING.widthBannerMainMobile || 1000} 
                                        height={THEME_SETTING.heightBannerMainMobile || 1500}/> 
                                    : 
                                    <ImageSet 
                                            //aspectRatio={THEME_SETTING.bannerMobileAspectRatio || 1000/1500} 
                                            // domainFrom={THEME_SETTING.domainFrom} 
                                            // domainTo={THEME_SETTING.domainTo}
                                            image={item?.mobile} 
                                            width={THEME_SETTING.widthBannerMainMobile || 1000} 
                                            height={THEME_SETTING.heightBannerMainMobile || 1500} 
                                            priority={true}/> 
                                }
                            </a>
                        </Mobile>
                    </div>
                ))}
            </Splide>
        </div>
      </div>
    );
};

export default BannerSlider
