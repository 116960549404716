import { replaceDomain } from "../../core-nextv3/util/util";
import { THEME_SETTING } from "../../setting/setting";
import styles from "./styles.module.scss";

const FeaturesColumns = ({ features }: any) => {
  return (
    <div className={styles.featureColumns}>
      <div className={styles.content}>
        {features?.map((feature: any) => (
          feature.status && <div className={styles.column} key={feature.id}>
            <img
              className={styles.columnIcon}
              src={replaceDomain(feature?.icon?._url, THEME_SETTING.domainFrom, THEME_SETTING.domainTo)}
              alt="Icon"
              width="3.5rem"
              height="3.5rem"
            />
            <div className={styles.info}>
              <p className={styles.title}>{feature?.title}</p>
              <p className={styles.text}>{feature?.subtitle}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturesColumns;
