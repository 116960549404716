import styles from "./styles.module.scss";
import { Options } from '@splidejs/splide';
import { CategoryItem } from "./CategoryItem";
import { Splide, SplideSlide } from '@splidejs/react-splide';

const CategoryCarousel = ({ categories, banners }: any) => 
{
	const mainOptions : Options = {
        perPage    : 4,
        perMove    : 1,
        rewind     : true,
        rewindByDrag: true,
        gap        : '2rem',
        pagination : false,
        slideFocus : true,
        arrows     : true,
        drag	   : true,
        start      : 1,
        breakpoints: {
            1024: {
                perPage: 2.2,
            },
        }
    };

  return (
    <>
        {categories && categories.length > 0 &&
            <div className={styles.categorySlider}>
                <div className={styles.content}>
                    <div className={styles.top}>
                        <p className={styles.title}>Categorias</p>
                    </div>
                    <Splide
                        options={mainOptions}
                        aria-labelledby="Banners"
                        className={styles.slideContent}
                    > 
                        {categories.map((category:any, index:any) => (
                            <SplideSlide key={index}>
                                <CategoryItem category={category} banners={banners} />
                            </SplideSlide>
                        ))}
                    </Splide>
                </div>
            </div>
        }
    </>
  );
};

export default CategoryCarousel;
