import styles from './styles.module.scss'
import { GetStaticProps } from 'next'
import withHeader from '../../utils/withHeader'
import { calls } from '../../core-nextv3/util/call.api'
// import { BestsellerMasonry } from '../BestsellerMasonry'
import {
  collectionProductVariant,
  getProduct,
} from '../../core-nextv3/product/product.api'
import {
  COLOR_TABLE_SETTING,
  HOME_PAGE_SETTING,
  PARTIAL_PRODUCT_VARIANT_SETTING,
  POPUP_PAGE_SETTING,
  THEME_SETTING,
  platform,
} from '../../setting/setting'
import { getDocument } from '../../core-nextv3/document/document.api'
// import { useEffect, useState } from 'react'
import { lazy, Suspense } from 'react'
import LoadingFallback from '@/component-nextv3/LoadingFallback'
import { RenderOnLoad } from '@/component-nextv3/RenderOnLoad'
import BannerSlider from '../BannerSlider'
import FeaturesColumns from '../FeaturesColumns'
import NewestProductsSlider from '../NewestProductsSlider'
import CollectionCarousel from '../CollectionCarousel'
import CategoryCarousel from '../CategoryCarousel'
import Matches from '@/component-nextv3/Matches'

const HomePage = ({
  colorTable,
  homePage,
  categoryProducts,
  saleProducts,
  newsProducts,
  bannersTopPage,
}: any) => 
{
  return (
    <div className={styles.homePage}>
      <div className={styles.content}>
        <BannerSlider images={homePage?.bannersHome} />
        {!THEME_SETTING.disabledFeatures && (
            <FeaturesColumns features={homePage?.features} />
        )}
        {homePage?.collectionProducts &&
          homePage?.collectionProducts.map((item: any, index: number) => (
                <NewestProductsSlider
                  allLink={`/colecao/${item.slug}`}
                  key={index}
                  title={item.name}
                  products={item.products}
                  colorTable={colorTable}
                />
          ))}
        {categoryProducts &&
          categoryProducts.map((item: any, index: number) => (
            <NewestProductsSlider
              allLink={`/categoria/${item.slug}`}
              key={index}
              title={item.name}
              products={item.products}
              colorTable={colorTable}
            />
          ))}
            {newsProducts && newsProducts?.length > 0 && (
              <NewestProductsSlider
                allLink={`/novidades/`}
                title='Novidades'
                products={newsProducts}
                colorTable={colorTable}
              />
            )}
          {saleProducts && saleProducts?.length > 0 && (
            <NewestProductsSlider
              allLink={`/sale/`}
              title='Sale'
              products={saleProducts}
              colorTable={colorTable}
            />
          )}
        {/* {homePage?.bestSellers?.products && homePage?.bestSellers?.products?.length > 0 ? (<BestsellerMasonry bestSellers={homePage?.bestSellers} />) : null} */}
        {homePage?.collection && homePage?.collection?.length > 0 && (
          <CollectionCarousel collection={homePage?.collection} />
        )}
        {homePage?.categories && (
          <CategoryCarousel categories={homePage.categories} banners={bannersTopPage?.categories} />
        )}
        {homePage?.bannersMatchhes && <Matches homePage={homePage} />}
        {/* <SaleBanner /> */}
        {/* <Journal /> */}
        {/* <Newsletter />  */}
        {/* <ProductModalHome popupPageDiscount={popupPageDiscount} /> */}
      </div>
    </div>
  )
}

const getStaticProps: GetStaticProps = () => withHeader(async () => 
{
    // HOME
    const [homePage, colorTable, popupPageResult] = await calls(
      getProduct(HOME_PAGE_SETTING),
      getDocument(COLOR_TABLE_SETTING),
      getDocument(POPUP_PAGE_SETTING)
    )

    const promises = []
    let categoryProducts = []

    if (!homePage.status) {
      return {
        notFound: !homePage.serverError,
        revalidate: true,
      }
    }

    // PRODUTOS DA CATEGORIA
    if (homePage.data && homePage.data.categoryProducts) 
    {
      for (const item of homePage.data.categoryProducts) 
      {
          const where = [
            {
              field: 'published',
              operator: '==',
              value: true,
            },
            {
              field: 'indexes.categoriesxcolorxsize',
              operator: 'combine',
              value: [
                {
                  referencePath: item.referencePath,
                },
              ],
            },
          ];

        	if(THEME_SETTING.filterPlatform)
          {
              where.push({
                  field: "indexes.platforms." + platform.value,
                  operator: "==",
                  value: true, 
              })
          }		

        promises.push(
          collectionProductVariant(
            PARTIAL_PRODUCT_VARIANT_SETTING.merge({
              perPage: 12,
              orderBy: 'categoryOrder',
              asc: false,
              where : where,
            })
          )
        )
      }

      const values: any = await Promise.all(promises)

      for (const key in homePage.data.categoryProducts) {
        homePage.data.categoryProducts[key].products =
          values[key].collection || []
      }

      categoryProducts = homePage.data.categoryProducts
    }

    let saleProducts: any = {}

    // SALE
    if (homePage.data && homePage.data.sale) 
    {
        const where = [
          {
            field: 'published',
            operator: '==',
            value: true,
          },
          {
            field: 'indexes.hasSale',
            operator: '==',
            value: true,
          },
        ];

        if(THEME_SETTING.filterPlatform)
        {
            where.push({
                field: "indexes.platforms." + platform.value,
                operator: "==",
                value: true, 
            })
        }		

        saleProducts = await collectionProductVariant(
          PARTIAL_PRODUCT_VARIANT_SETTING.merge({
            perPage: 12,
            orderBy: 'saleOrder',
            asc: false,  
            where : where,        
          })
        )
    }

    let newsProducts: any = {}

    // NOVIDADES
    if (homePage.data && homePage.data.news) 
    {
        const where = [
          {
            field: 'published',
            operator: '==',
            value: true,
          },
        ];

        if(THEME_SETTING.filterPlatform)
        {
            where.push({
                field: "indexes.platforms." + platform.value,
                operator: "==",
                value: true, 
            })
        }	

        newsProducts = await collectionProductVariant(
          PARTIAL_PRODUCT_VARIANT_SETTING.merge({
            perPage: 12,
            orderBy: 'order',
            where : where, 
          })
        )
    }

    return {
      revalidate: THEME_SETTING.revalidate,
      props: {
        homePage: homePage?.data || [],
        categoryProducts: categoryProducts || [],
        colorTable: colorTable?.data || {},
        saleProducts: saleProducts?.collection || [],
        newsProducts: newsProducts?.collection || [],
        popupPage: popupPageResult?.data,
      },
    }
  })

export { getStaticProps as GetStaticProps, HomePage }
