import styles from "./styles.module.scss";
import { ImageSet } from "@/component-nextv3/ImageSet";

export const CategoryItem = ({ category, banners }: any) => {
    const getIamge = () =>
    {
        if(banners)
        {
            if(category && banners)
            {
                for(const item of banners)
                {
                    if(item.status && item.category && category.id == item.category.id)
                    {
                        return(
                            <a href={`/categoria/${category?.slug}`}>
                                <img
                                    alt=""
                                    className={styles.mobile}
                                    src={item?.categoryMobile?._url}
                                />
                                <p className={styles.name}>{category.name}</p>
                            </a>
                        )
                    }
                }
            }
        }
    }

    return(
        <div className={styles.slideItem + ' splide__slide'}>
            {banners ?
                <>
                    {getIamge()}
                </>
                :
                <a href={`/categoria/${category?.slug}`}>
                    <ImageSet image={category?.mobile}/>
                    <p className={styles.name}>{category.name}</p>
                </a>
            }
        </div>
    );
}