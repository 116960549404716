import styles from './styles.module.scss'
import { ImageSet } from '../../component-nextv3/ImageSet'
import { Options } from '@splidejs/splide'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { useResponsive } from '../../core-nextv3/util/useResponsive'
import { removeSlidesEmpty } from '@/core-nextv3/util/util'

const CollectionCarousel = ({ collection }: any) => 
{
  const mainOptions: Options = {
    role: 'banner',
    perPage: 4,
    perMove: 1,
    rewind: true,
    rewindByDrag: true,
    gap: '2rem',
    pagination: false,
    slideFocus: true,
    arrows: true,
    drag: true,
    start: 1,
    breakpoints: {
      1024: {
        perPage: 2.2,
      },
    },
  }

  return (
    <>
      {collection && collection.length > 0 && (
        <div className={styles.collectionSlider}>
          <div className={styles.content}>
            <div className={styles.top}>
              <p className={styles.title}>Coleções</p>
            </div>
            <Splide
              options={mainOptions}
              aria-labelledby=''
              className={styles.slideContent}
              onReady={removeSlidesEmpty}
            >
              {collection.map((item: any, index: any) => (
                <div
                  key={index}
                  className={styles.slideItem + ' splide__slide'}
                >
                  <a href={`/colecao/${item?.slug}`}>
                    <ImageSet image={item?.mobile} />
                  </a>
                  <p className={styles.name}>{item.name}</p>
                </div>
              ))}
            </Splide>
          </div>
        </div>
      )}
    </>
  )
}

export default CollectionCarousel;