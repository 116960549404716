import Link from "next/link";
import { useRouter } from "next/router";
import styles from "./styles.module.scss";
import { Options } from '@splidejs/splide'
// import { SectionTitle } from "../SectionTitle";
import { THEME_SETTING } from "../../setting/setting";
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { ImageSet } from "../../component-nextv3/ImageSet";
import { removeSlidesEmpty } from "@/core-nextv3/util/util";

const Matches = ({ homePage }: any) => {
  // console.log('matches', homePage.bannersMatchhes)
  const router = useRouter();

  const mainOptions: Options = {
    role: 'banner',
    perPage: 4,
    perMove: 1,
    rewind: true,
    rewindByDrag: true,
    gap: '2rem',
    pagination: false,
    slideFocus: true,
    arrows: true,
    drag: true,
    start: 1,
    breakpoints: {
      1024: {
        perPage: 2,
        gap: "1rem"
      },
    },
  }

  return (
    <section className={styles.matches}>
      <div className={styles.content}>
        {/* <SectionTitle category="" sectionTitle="Matches" /> */}
        <h1 className={styles.titleMatches}>Categorias</h1>
        <div className={styles.slide}>
          <Splide
            options={mainOptions}
            aria-labelledby=''
            className={styles.slideContent}
            onReady={removeSlidesEmpty}
          >
            {homePage?.bannersMatchhes?.map((matche: any, index: any) => (
            <SplideSlide key={index}>
              <figure onClick={() => router.push(matche?.url, undefined, { scroll: true })}>
                  <div className={styles.imageContent}> 
                      <ImageSet
                        image={matche?.mobile}
                        domainFrom={THEME_SETTING.domainFrom} 
                        domainTo={THEME_SETTING.domainTo} 
                        aspectRatio={1500/2160}
                        width={2000}                    
                      />
                  </div>
                </figure>
            </SplideSlide>
            ))}
          </Splide>
        </div>
        {/* <div className={styles.matchesList}>
          {homePage?.bannersMatchhes?.map((matche: any, index: any) => (
            <figure key={index} onClick={() => router.push(matche?.url, undefined, { scroll: true })}>
              <div className={styles.imageContent}> 
                  <ImageSet
                    image={matche?.mobile}
                    domainFrom={THEME_SETTING.domainFrom} 
                    domainTo={THEME_SETTING.domainTo} 
                    aspectRatio={1500/2160}
                    width={2000}                    
                  />
              </div>
              <Image
                src={matche?.mobile?._url}
                priority
                width={800}
                height={1800}
                alt="photo"
                onClick={() => router.push(`/categoria/${matche?.slug}`)}
              />
              <div className={styles.label}>
                <p>{matche?.name}</p>
                <Link href={`/categoria/${matche?.slug}`}>View all</Link>
              </div>
            </figure>
          ))}

          <figure>
            <Image
              src="https://au.lattelierstore.com/wp-content/uploads/2021/09/pc-CityForest.jpg"
              priority
              width={800}
              height={1800}
              alt="photo"
            />
             <div>
              <p>City Forest</p>
              <Link href="/produtos">View all</Link>
            </div>
          </figure>

          <figure>
            <Image
              src="https://au.lattelierstore.com/wp-content/uploads/2021/09/pc-SpringPicnic.jpg"
              priority
              width={800}
              height={1800}
              alt="photo"
            />
             <div>
              <p>Spring Picnic</p>
              <Link href="/produtos">View all</Link>
            </div>
          </figure>
          <figure>
            <Image
              src="https://au.lattelierstore.com/wp-content/uploads/2021/09/pc-GentleDays.jpg"
              priority
              width={800}
              height={1800}
              alt="photo"
            />
            <div>
              <p>Gentle Days</p>
              <Link href="/produtos">View all</Link>
            </div>
          </figure>

          <figure>
            <Image
              src="https://au.lattelierstore.com/wp-content/uploads/2021/09/pc-CityForest.jpg"
              priority
              width={800}
              height={1800}
              alt="photo"
            />
             <div>
              <p>City Forest</p>
              <Link href="/produtos">View all</Link>
            </div>
          </figure>

          <figure>
            <Image
              src="https://au.lattelierstore.com/wp-content/uploads/2021/09/pc-SpringPicnic.jpg"
              priority
              width={800}
              height={1800}
              alt="photo"
            />
             <div>
              <p>Spring Picnic</p>
              <Link href="/produtos">View all</Link>
            </div>
          </figure>
        </div> */}
      </div>
    </section>
  );
};

export default Matches;